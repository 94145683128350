/* @flow */

import React from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import MainLogo from "icons/logo.svg";

import styles from "./styles.scss";

type Props = {
  className?: string,
};

const Logo = ({ className, ...props }: Props): React$Node => {
  const t = useTranslate();

  return (
    <div {...props} className={cn(styles.block, className)}>
      <MainLogo
        alt={t("LOGO.ALT")}
        title={t("LOGO.TITLE")}
        className={styles.logoMain}
      />
      <span className={styles.suffix}>{t("APP.SUFFIX")}</span>
    </div>
  );
};

export default Logo;
