/* @flow */

import React, { useEffect, useContext } from "react";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import { useData } from "crustate/react";
import { getQuoteData, setQuoteDiscountCode, removeQuoteDiscountCode } from "state/quote";
import { QuoteData, CmsData } from "data";
import useFormat from "helpers/use-format";
import { StripeCheckoutContainer } from "@crossroads/shop-views";

import CmsPageView from "components/CmsPageView";
import PaymentMethodsCards from "components/CheckoutView/PaymentMethodsCards";

type Props = {
  open: boolean,
  setOpen: boolean => void,
};

const Step2 = ({ open, setOpen }: Props): React$Node => {
  const storeInfo = useContext(StoreInfoContext);
  const client = useClient();
  const termsData = useData(CmsData);
  const quoteData = useData(QuoteData);
  const quote = getQuoteData(useData(QuoteData));
  const { formatPrice } = useFormat();

  const discountCodeField = false;

  // Open summary when this route renders
  useEffect(() => {
    setOpen(true);
  }, [setOpen]);

  return (
    (termsData.state === "LOADED" &&
      <StripeCheckoutContainer
        open={open}
        setOpen={setOpen}
        enableDiscountCode={discountCodeField}
        formatPrice={formatPrice}
        client={client}
        storeInfo={storeInfo}
        quote={quote}
        quoteData={quoteData}
        setQuoteDiscountCode={setQuoteDiscountCode}
        removeQuoteDiscountCode={removeQuoteDiscountCode}
        agreement={<CmsPageView cmsPage={termsData.data} />}
        paymentMethodsCards={<PaymentMethodsCards />}
        browserProcess={process.browser}
      />
    )
  );
};

export default Step2;
