/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";

import bindQuoteEffects from "./quote";
import bindRouteEffects from "./route";
import bindOrderEffects from "./order";
import bindCmsEffects from "./cms";

export const registerClient = (storage: Storage, client: Client<{}>) => {
  bindRouteEffects(storage, client);
  bindQuoteEffects(storage, client);
  bindOrderEffects(storage, client);
  bindCmsEffects(storage, client);
};
