/* @flow */

import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import SystemMessages from "components/SystemMessages";
import Wrapper from "components/Wrapper";
import Logo from "components/AppHeader/Logo";

import styles from "./styles.scss";

type Props = {
  children: React$Node,
};

const GuestApp = ({ children }: Props): React$Node => {
  return (
    <div className={styles.block}>
      <Helmet />
      <SystemMessages />
      <header className={styles.header}>
        <Wrapper>
          <Link to="/">
            <Logo />
          </Link>
        </Wrapper>

      </header>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};

export default GuestApp;
