/* @flow */

import type { StateData } from "crustate/react";

import { createStateData } from "crustate/react";

import { CheckoutStepModel } from "state/checkout-step";
import { MessagesModel } from "@crossroads/shop-state/messages";
import { AllProductsModel } from "@crossroads/shop-state/all-products";
import { OrderModel } from "state/order";
import { CmsModel } from "state/cms";
import { QuoteModel } from "state/quote";
import { RouteModel } from "@crossroads/shop-state/route";
import { ViewModeModel } from "state/view-mode";

export const AllProductsData: StateData<typeof AllProductsModel> =
  createStateData<typeof AllProductsModel>(AllProductsModel);
export const CheckoutStepData: StateData<typeof CheckoutStepModel> =
  createStateData<typeof CheckoutStepModel>(CheckoutStepModel);
export const MessagesData: StateData<typeof MessagesModel> =
  createStateData<typeof MessagesModel>(MessagesModel);
export const OrderData: StateData<typeof OrderModel> =
  createStateData<typeof OrderModel>(OrderModel);
export const CmsData: StateData<typeof CmsModel> =
  createStateData<typeof CmsModel>(CmsModel);
export const QuoteData: StateData<typeof QuoteModel> =
  createStateData<typeof QuoteModel>(QuoteModel);
export const RouteData: StateData<typeof RouteModel> =
  createStateData<typeof RouteModel>(RouteModel);
export const ViewModeData: StateData<typeof ViewModeModel> =
  createStateData<typeof ViewModeModel>(ViewModeModel);
