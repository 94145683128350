/* @flow */

import type { BreadcrumbLink } from "@crossroads/ui-components";

import { useBrowser } from "@awardit/react-use-browser";
import { useCampaign } from "context/campaign-context";
import { useLocation } from "react-router-dom";

type Props = ?{
  current?: string,
  fallbackCategories?: $ReadOnlyArray<{
    url: string,
    name: string,
    children: Array<{ name: string, url: string }> }>,
};

const useBreadcrumbLinks = (props: Props): Array<BreadcrumbLink> => {
  const browser = useBrowser();
  const { categories } = useCampaign();
  const location = useLocation();
  const links: Array<BreadcrumbLink> = [];

  if (browser && location.state?.breadcrumbLinks) {
    // If rendering in the browser and there are breadcrumb-links
    // provided in state, use them.
    return location.state.breadcrumbLinks;
  }

  // If no breadcrumb-links where provided in location.state,
  // use pathname to find breadcrumbs.
  const pathnameParts = location.pathname.slice(1).split("/");

  for (let i = 0; i < pathnameParts.length; i++) {
    const category = categories.find(c => c.url === `/${pathnameParts[i]}` && c.name !== props?.current);

    if (category) {
      links.push({
        title: category.name,
        pathname: category.url,
        hint: {
          name: category.name,
        },
      });
    }
  }

  if (links.length === 0 && props?.fallbackCategories && props.fallbackCategories.length > 0) {
    // If no links found to be used and fallbackcategories are provided,
    // try to find a proper fallback to use.
    // The rules are that the first category in the list with
    // children shall be used, otherwise just the first in the list.
    const longest = props.fallbackCategories.reduce((a, c) => {
      if (!a) {
        return c;
      }

      if (a.children.length > 0) {
        return a;
      }

      if (c.children.length > 0) {
        return c;
      }

      return a;
    }, null);

    if (longest) {
      links.push({
        title: longest.name,
        pathname: longest.url,
        hint: {
          name: longest.name,
        },
      });

      if (longest.children.length > 0) {
        // Same goes for picking the child category, the first one is picked.
        links.push({
          title: longest.children[0].name,
          pathname: longest.children[0].url,
          hint: {
            name: longest.children[0].name,
          },
        });
      }
    }
  }

  return links;
};

export default useBreadcrumbLinks;
