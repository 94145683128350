/* @flow */

import type { CmsPage } from "shop-state/types";

import React, { useEffect, useContext } from "react";
import { useData } from "crustate/react";
import { CookieDialogue } from "@crossroads/ui-components";
import { AnalyticsContext } from "@crossroads/analytics";
import { CmsData } from "data";
import config from "config";

import cn from "classnames";
import styles from "./styles.scss";

type Props = {
  isVisible: boolean,
  setConsent: (value: string) => void,
  consent: string,
  consentRead: boolean,
  setToggleCookieDialogue: (value: boolean) => void,
};

const CookieConsent = ({
  isVisible,
  setConsent,
  consent,
  consentRead,
  setToggleCookieDialogue,
}: Props): React$Node => {
  const privacyData = useData(CmsData);
  const privacyContent: CmsPage | any = privacyData.data !== null ? privacyData.data : null;
  const gaContext = useContext(AnalyticsContext);

  useEffect(() => {
    if (consentRead && consent !== "initial") {
      if (consent === "accepted" || consent === "declined") {
        setConsent("initial");
        return;
      }

      const consentJson = JSON.parse(consent);

      if (consentJson.necessary) {
        gaContext.grantConsentNecessary();
      }

      if (consentJson.statistical) {
        gaContext.grantConsentStatistical();
      }
      else {
        gaContext.denyConsentStatistical();
      }
    }

    if (consent === "initial") {
      setToggleCookieDialogue(true);
    }
  }, [consentRead, consent]);

  return (
    privacyData.state === "LOADED" &&
      <div
        className={cn(
          styles.block,
          { [styles.visible]: isVisible === true }
        )}
      >
        <CookieDialogue
          content={privacyContent}
          isVisible={isVisible}
          config={config}
          consent={consent}
          setConsent={setConsent}
          setToggleCookieDialogue={setToggleCookieDialogue}
        />
      </div>
  );
};

export default CookieConsent;
