/* @flow */

import React, { createContext, useState, useContext, useMemo } from "react";

export type Category = { +name: string, +url: string };

type Props = {
  categories: $ReadOnlyArray<Category>,
  children: React$Node,
  loggedIn: boolean,
  rootCategory: ?Category,
};

type UseCampaingReturnValue = {|
  +categories: $ReadOnlyArray<Category>,
  +rootCategory: ?Category,
  +loggedIn: boolean,
  +setCategories: $ReadOnlyArray<Category> => void,
  +setRootCategory: Category => void,
  +setLoggedIn: boolean => void,
|};

const CampaignContext = createContext();

const CampaignProvider = ({
  children,
  categories: c,
  loggedIn: l,
  rootCategory: rc }: Props): React$Node => {
  const [loggedIn, setLoggedIn] = useState<boolean>(l);
  const [categories, setCategories] = useState<$ReadOnlyArray<Category>>(c);
  const [rootCategory, setRootCategory] = useState<?Category>(rc);

  const value = useMemo(() => ({
    loggedIn,
    rootCategory,
    setRootCategory,
    setLoggedIn,
    categories,
    setCategories,
  }), [loggedIn, setLoggedIn, categories, setCategories, rootCategory, setRootCategory]);

  return (
    <CampaignContext.Provider value={value}>
      {children}
    </CampaignContext.Provider>
  );
};

const useCampaign = (): UseCampaingReturnValue => {
  const context = useContext(CampaignContext);
  if (context === undefined) {
    throw new Error("useCampaign must be used within a CampaignProvider");
  }

  return context;
};

export {
  CampaignProvider,
  useCampaign,
};
