/* @flow */

import type { Product, ProductCardProduct } from "shop-state/types";
import type { Category } from "context/campaign-context";
import { useEffect, useState } from "react";

type Error = {
  field: string,
  error: string,
};

declare type DOMEvent<T> = {
  target: T,
} & Event;

export const focusInvalidField = (e: SyntheticEvent<HTMLFormElement>, errors: Array<Error>) => {
  const field = e.currentTarget.querySelector(`[name='${errors[0].field}']`);

  if (field) {
    field.focus();
  }
};

export const loadScript = (isLoaded: () => boolean, src: string, cb: () => void): void => {
  if (!process.browser) {
    return;
  }

  if (isLoaded()) {
    cb();

    return;
  }

  const head = document.querySelector("head");
  const script = document.createElement("script");

  script.src = src;

  /* eslint-disable unicorn/prefer-add-event-listener */
  script.onload = () => {
    script.onload = null;

    cb();
  };
  /* eslint-enable unicorn/prefer-add-event-listener */

  if (head) {
    head.append(script);
  }
};

export const useCurrentSrc = (ref: { current: ?HTMLImageElement }, defaultSrc: string): string => {
  const [image, setImage] = useState(defaultSrc);

  useEffect(() => {
    const { current } = ref;

    if (current && current.currentSrc) {
      setImage(current.currentSrc);
    }
  }, [ref]);

  return image;
};

export const isValidHex = (hex: string): boolean => /^[\da-f]{6}$/i.test(hex);

export const sortCategories = (
  categories: $ReadOnlyArray<Category>,
  locale: string): Array<Category> => {
  return categories.slice().sort((a, b) => a.name.localeCompare(b.name, locale));
};

export const getCategories = (categories: {
  children: $ReadOnlyArray<{
    name: string,
    url: string,
    ...
  }>,
  name: string,
  parent: ?{
    children: $ReadOnlyArray<{
      name: string,
      url: string,
      ...
    }>,
    name: string,
    url: string,
    ...
  },
  url: string,
}, locale: string): Array<Category> => {
  if (categories.parent) {
    return sortCategories(categories.parent.children, locale);
  }

  return sortCategories(categories.children, locale);
};

export const getRootCategory = (category: {
  +name: string,
  +url: string,
  +parent: ?{
    name: string,
    url: string,
  },
}): { name: string, url: string} => {
  if (category.parent) {
    return {
      name: category.parent.name,
      url: category.parent.url,
    };
  }

  return {
    name: category.name,
    url: category.url,
  };
};

export const removeExampleEmail = <T: { email: ?string }>(object: T): T => {
  if (object && object.email && /@example.com$/.test(object.email)) {
    return {
      ...object,
      email: null,
    };
  }

  return object;
};

export const getLowestMSRP = (product: ProductCardProduct | Product): ?number => {
  if (product.type === "configurable") {
    const cheapest = product.options.items.reduce((a, c) => {
      const { msrp } = c.product.attributes;
      if (typeof msrp === "number") {
        if (a === null) {
          return msrp;
        }

        if (msrp < a) {
          return msrp;
        }
      }

      return a;
    }, null);

    return cheapest;
  }

  return product.attributes.msrp;
};
