/* @flow */

import type { ProductCardProduct, GAProduct } from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import React, { useRef, useContext, useEffect, useState, useMemo } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { AnalyticsContext, ProductLink } from "@crossroads/analytics";
import useFormat from "helpers/use-format";
import { getLowestMSRP } from "helpers/utils";
import { formatConfigColors } from "components/ProductOptions/color";
import { Square, ProductListMedia } from "@crossroads/ui-components";

import styles from "./styles.scss";

type ProductCardProps = {
  product: ProductCardProduct,
  className?: string,
  list?: string,
  position?: number,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
};

const ProductCard = ({
  product,
  className,
  breadcrumbLinks,
  position,
  list,
}: ProductCardProps): React$Node => {
  const t = useTranslate();
  const gaContext = useContext(AnalyticsContext);
  const { formatPrice } = useFormat();
  const gaRef: { current: null | Element } = useRef(null);
  const [imageSrc, setImageSrc] = useState<string>("");
  const options = product.type === "configurable" ? product.options.items : [];
  const { price: { incVat: price }, attributes: { showDiscount } } = product;
  const msrp = useMemo(() => getLowestMSRP(product), [product]);
  const discount = typeof msrp === "number" ?
    Math.floor((msrp - price) / msrp * 100) : 0;

  const mapGaObject = (product: ProductCardProduct): GAProduct => {
    const gaObject = {
      item_id: product.sku, // eslint-disable-line camelcase
      item_name: product.name, // eslint-disable-line camelcase
      item_brand: product.attributes.manufacturer, // eslint-disable-line camelcase
      price: product.price.incVat,
      index: Number(position) + 1,
      item_list_name: list ?? "", // eslint-disable-line camelcase
    };

    if (product.categories === undefined || product.categories.length === 0) {
      return gaObject;
    }

    product.categories.forEach((c, i) => {
      if (i === 0) {
        gaObject.item_category = c.name; // eslint-disable-line camelcase
      }
      else {
        gaObject[`item_category${i + 1}`] = c.name;
      }
    });

    return gaObject;
  };

  useEffect(() => {
    const gaObject = mapGaObject(product);

    if (!gaRef.current) {
      return;
    }

    gaContext.register(gaRef.current, gaObject);
  }, [gaRef]);

  return (
    <ProductLink
      className={cn(styles.block, className)}
      list={list}
      to={{
        pathname: product.url,
        state: {
          hint: {
            type: "product",
            product,
            image: imageSrc,
          },
          breadcrumbLinks,
          qty: 1,
          list,
          position: Number(position) + 1,
        },
      }}
      product={{
        name: product.name,
        sku: product.sku,
        price: product.price,
        qty: 1,
        attributes: {
          manufacturer: product.attributes.manufacturer,
        },
        categories: product.categories,
      }}
      innerRef={gaRef}
      position={Number(position) + 1}
    >
      <div className={styles.hoverFG} />

      <div className={styles.colors}>
        {options
          .filter(x => Boolean(x.product.attrLabels.color))
          .map((x, i) => (
            <div
              key={i}
              style={{ background: `linear-gradient(${formatConfigColors(x.product.attrLabels.color?.title)})` }} />
          ))}
      </div>

      <div className={styles.header}>
        <ProductListMedia
          className={styles.imageWrapper}
          product={product}
          getCurrentImageSrc={x => setImageSrc(x)}
        />

      </div>

      <div className={styles.body}>
        <div className={styles.top}>
          <span className={styles.name}>{product.name}</span>
          <span className={styles.brand}>{product.attributes.manufacturer}</span>
        </div>

        <div className={styles.bottom}>
          <div className={styles.priceWrapper}>
            <span className={styles.price}>{formatPrice(price)}</span>
            {discount > 0 && discount < 100 && typeof msrp === "number" &&
              <span className={styles.msrp}>{`${t("PRODUCT.MSRP")} ${formatPrice(msrp)}`}</span>
            }
          </div>
          {showDiscount === true && discount > 0 && discount < 100 && <div className={styles.saleTag}>{`-${discount}%`}</div>}
        </div>
      </div>
    </ProductLink>
  );
};

export const DummyCard = ({ className }: { className?: string }): React$Node => {
  return (
    <div className={cn(className, styles.block, styles.dummy)}>
      <div className={styles.imageWrapper}>
        <div className={styles.image}>
          <Square />
        </div>
      </div>

      <div className={styles.body}>
        <p className={styles.brand}>&nbsp;</p>
        <span className={styles.name}>&nbsp;</span>

        <div>
          <p className={styles.price}>&nbsp;</p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
