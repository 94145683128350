/* @flow */

import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import { Form, rules, isRequired } from "@awardit/formaggio";
import { focusInvalidField, getCategories, getRootCategory } from "helpers/utils";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import Field from "components/Field";
import { useSendMessage } from "crustate/react";
import { addMessageTranslated } from "@crossroads/shop-state/messages";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import { campaignCodeValidateFull } from "queries";
import { useHistory } from "react-router";
import { useCampaign } from "context/campaign-context";
import { parseParams } from "helpers/location-search-string";

import styles from "./styles.scss";

const validation = rules([
  isRequired("password"),
]);

const LoginView = (): React$Node => {
  const t = useTranslate();
  const client = useClient();
  const history = useHistory();
  const sendMessage = useSendMessage();
  const [state, setState] = useState<{ [string]: any }>({});
  const info = useContext(StoreInfoContext);
  const { setCategories, setLoggedIn, setRootCategory } = useCampaign();
  const errors = validation((state));

  useEffect(() => {
    const params = parseParams(history.location.search);

    if (params.error) {
      const error = Array.isArray(params.error) ? params.error[0] : params.error;
      sendMessage(addMessageTranslated(t(`STATUS_CODE.CAMPAIGN.${error}`), "error"));
    }
  }, [history.location]);

  const submit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { campaignCodeValidate: result } =
      await client(campaignCodeValidateFull, { code: state.password });

    if (result.result !== "success") {
      history.push(`${history.location.pathname}?error=${result.result}`);
      return;
    }

    const product = result.campaignCode?.product;
    const redirectUrl = product ? product.url : (result.campaignCode?.category.url || "");

    let hint = {};

    if (product) {
      const { tinyImage, smallImage, mediumImage, largeImage } = product.attributes;
      const defaultImage = tinyImage || smallImage || mediumImage || largeImage || { x1: "", x2: "" };

      hint = {
        type: "product",
        product,
        image: defaultImage,
      };
    }
    else {
      hint = {
        type: "category",
        category: {
          name: result.campaignCode?.category.name || "",
        },
      };
    }

    setRootCategory(result.campaignCode ?
      getRootCategory(result.campaignCode.category) :
      { name: "", url: "" }
    );

    setCategories(result.campaignCode ?
      getCategories(result.campaignCode.category, info.info.locale.split("_")[0]) :
      []
    );

    setLoggedIn(true);

    history.push(redirectUrl, { hint });
  };

  return (
    <div className={styles.block}>
      <Helmet title="Login" />
      <Wrapper className={styles.wrapper}>
        <div className={styles.container}>
          <h1 className={styles.title}>{t("LOGINVIEW.TITLE")}</h1>
          <p className={styles.description}>
            {t("LOGINVIEW.SUBTITLE")}
          </p>
          <Form
            value={(state)}
            errors={errors}
            className={styles.form}
            onError={focusInvalidField}
            onSubmit={submit}
            onChange={x => setState({ ...state, ...(x) })}
          >
            <div className={styles.input}>
              <Field type="password" name="password" autoComplete="off" placeholder={t("LOGINVIEW.PASSWORD")} className={styles.input} />
            </div>

            <Button
              type="submit"
              className={styles.submit}
              variant="primary"
            >
              {t("LOGINVIEW.SUBMIT")}
            </Button>
          </Form>
        </div>
      </Wrapper>
    </div>
  );
};

export default LoginView;
