/* @flow */

import type { Match } from "react-router";

import React, { useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Route, Switch } from "react-router";
import cn from "classnames";
import { Menu, CartMini } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import { QuoteData } from "data";
import { useData } from "crustate/react";
import Wrapper from "components/Wrapper";
import CartCounter from "components/AppHeader/CartCounter";
import useCheckoutSteps from "helpers/use-checkout-steps";
import Logo from "components/AppHeader/Logo";
import HamburgerMenu from "components/AppHeader/HamburgerMenu";
import { MODE } from "state/view-mode";
import { useCampaign } from "context/campaign-context";
import { useUi } from "helpers/ui";
import CustomerServiceIcon from "icons/customer-service.svg";
import HamburgerIcon from "icons/hamburger.svg";

import styles from "./styles.scss";

const CheckoutSteps = (): React$Node => {
  const { steps, currentStep } = useCheckoutSteps();

  return (
    <div className={styles.steps}>
      {steps.map((step, id) => (
        <Link
          key={step.id}
          className={cn(styles.step, { [styles.active]: step.id === currentStep.id })}
          to={step.link}
        >
          {`${id + 1}. ${step.title}`}
        </Link>
      ))}
    </div>
  );
};

export const NavLinks = (): React$Node => {
  const t = useTranslate();
  const { viewMode } = useUi();
  const { categories } = useCampaign();
  const navLinkIsActive = (match: Match) => Boolean(match && viewMode === MODE.NORMAL);

  if (categories.length === 0) {
    return null;
  }

  return (
    <Menu title={t("HEADER.LINKS")} counter={categories.length} className={cn(styles.item__allproducts)}>
      {categories.map(category => (
        <NavLink
          key={category.name}
          to={category.url}
          activeClassName={styles.active}
          className={cn(styles.item)}
          isActive={navLinkIsActive}
        >
          {category.name}
        </NavLink>
      ))}
    </Menu>
  );
};

const Nav = ({ onCheckout }: { onCheckout: boolean }): React$Node => {
  const t = useTranslate();
  const location = useLocation();
  const quoteData = useData(QuoteData);
  const showHeader = true;
  const {
    setHamburgerOpen,
    toggleHamburger,
    setSubNavOpen,
    viewMode,
    setViewMode,
  } = useUi();
  const { rootCategory } = useCampaign();
  const navLinkIsActive = (match: Match) => Boolean(match && viewMode === MODE.NORMAL);

  // Close menus when switching page
  useEffect(() => {
    setViewMode(MODE.NORMAL);
    setSubNavOpen(false);
    setHamburgerOpen(false);
  }, [setViewMode, setSubNavOpen, setHamburgerOpen, location.pathname]);

  return (
    <div className={cn(styles[`mode__${viewMode}`], { [styles.hide]: onCheckout })}>
      <div className={styles.block}>
        <Wrapper className={styles.wrapper}>
          <div className={styles.left}>
            <Link className={cn(styles.item, styles.item__logo)} to={rootCategory?.url || "/"}>
              <Logo
                className={styles.logo}
                onClick={() => setViewMode(MODE.NORMAL)}
              />
            </Link>

            <div className={styles.links}>
              <Switch>
                <Route path="/checkout/success" component={NavLinks} />
                <Route path="/checkout" component={CheckoutSteps} />
                <Route component={NavLinks} />
              </Switch>
            </div>
          </div>
          <div className={styles.right}>
            <nav className={styles.nav}>
              <NavLink
                to={t("CUSTOMER_SERVICE.LINK")}
                activeClassName={styles.active}
                className={cn(styles.item, styles.item__customerService)}
                isActive={navLinkIsActive}
              >
                <span className="sr-only">{t("CUSTOMER_SERVICE.TEXT")}</span>
                <CustomerServiceIcon aria-hidden="true" focusable="false" />
              </NavLink>

              {!onCheckout &&
              <CartCounter
                className={cn(styles.item, styles.item__cartCounter)}
                isOpen={viewMode === MODE.CART}
                openMiniCart={() =>
                  setViewMode(viewMode === MODE.CART ? MODE.NORMAL : MODE.CART)
                } />}
            </nav>
          </div>

          <span
            className={cn(styles.item, styles.hamburger, styles.padding)}
            onClick={toggleHamburger}
          >
            <HamburgerIcon />
          </span>

          {viewMode === MODE.CART &&
            <CartMini
              quoteData={quoteData}
              showHeader={showHeader}
              altUrl="/checkout/cart"
              ctaUrl="/checkout/overview"
              className={cn(styles.desktop, styles.miniCart)}
              onClose={() => setViewMode(MODE.NORMAL)}
            />
          }
        </Wrapper>

        <HamburgerMenu />
      </div>

      {viewMode === MODE.CART &&
        <CartMini
          quoteData={quoteData}
          showHeader={showHeader}
          altUrl="/checkout/cart"
          ctaUrl="/checkout/overview"
          className={cn(styles.mobile, styles.miniCart)}
          onClose={() => setViewMode(MODE.NORMAL)}
        />
      }

      {viewMode !== MODE.NORMAL &&
        <div className={styles.dim} onClick={() => setViewMode(MODE.NORMAL)} />
      }
    </div>
  );
};

export default Nav;
