/* @flow */

import React, { memo } from "react";
import type { ProductCardProduct } from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";
import cn from "classnames";
import ProductCard, { DummyCard } from "components/ProductCard";
import { useVisibilityObservable } from "@crossroads/ui-components";

import styles from "./styles.scss";

/*
TODO: import and extend ui-comopnents ProductList with local Cards instead

import { ProductList, ListItem } from "@crossroads/ui-components";
import ProductCard, { DummyCard } from "components/ProductCard";
*/

type Props = {
  children?: React$Node,
  products?: $ReadOnlyArray<?ProductCardProduct>,
  heading?: string,
  productsPerRow?: 4 | 3 | 2,
  loading?: boolean,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
};

type ItemProps = {
  loading?: boolean,
  product: ProductCardProduct,
  idx?: number,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
  category?: string,
};

type DummyItemProps = {
  idx?: number,
};

export const ListItem: React$AbstractComponent<ItemProps, mixed> = memo<ItemProps>(({
  product,
  breadcrumbLinks,
  idx,
  loading,
  category,
}: ItemProps) => {
  const el = useVisibilityObservable();

  if (loading === true) {
    return <ListItemDummy idx={idx} />;
  }

  return (
    <div ref={el} className={styles.item} data-idx={idx}>
      <ProductCard
        className={styles.itemInner}
        product={product}
        breadcrumbLinks={breadcrumbLinks}
        position={idx}
        list={category}
      />
    </div>
  );
});

export const ListItemDummy: React$AbstractComponent<DummyItemProps, mixed> = memo<DummyItemProps>(({
  idx,
}: DummyItemProps) => {
  const el = useVisibilityObservable();

  return (
    <div ref={el} className={styles.item} data-idx={idx}>
      <div className={styles.itemInner}>
        <DummyCard />
      </div>
    </div>
  );
});

const ProductList = ({
  products,
  children,
  breadcrumbLinks,
  heading,
  productsPerRow = 4,
  loading = false,
}: Props): React$Node => {
  return (
    <div>
      {heading &&
        <h3>{heading}</h3>
      }
      <div className={cn(
        styles.block,
        [styles["block__perRow" + productsPerRow]]
      )}
      >
        {products ? products.map((x, i) =>
          x ?
            <ListItem
              key={x.sku}
              loading={loading}
              breadcrumbLinks={breadcrumbLinks}
              product={x}
              position={i}
              category={heading}
            /> :
            <ListItemDummy key={i} className={styles.itemInner} />
        ) : children}
      </div>
    </div>
  );
};

export default ProductList;
