/* @flow */

import type { OptionProduct } from "shop-state/types";
import React from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { Button } from "@crossroads/ui-components";
import { isValidHex } from "helpers/utils";

import styles from "./styles.scss";

type Props = {
  items: Array<{
    key?: string,
    buyRequest: ?string,
    product: OptionProduct,
    label: string,
    price?: {
      incVat: number,
    },
  }>,
  value: ?string,
  onChange: (o: any) => void,
};

const stutter = (delay: number, initialDelay: number) => (idx: number): string => {
  return `${initialDelay + (delay * idx)}ms`;
};

export const formatConfigColors = (rawColor: ?string): string => {
  const FALLBACK = "#eee";

  if (!rawColor) {
    return FALLBACK;
  }

  const colors = rawColor.trim().split("-");

  return colors.every(isValidHex) ? `#${colors.join(", #")}` : FALLBACK;
};

export const ColorSelect = ({
  items,
  value,
  onChange,
}: Props): React$Node => {
  const t = useTranslate();
  const _stutter = stutter(40, 100);
  const selectedItem = items.find(x => x.buyRequest === value);
  const selectedColor = selectedItem ? selectedItem.label : null;

  return (
    <div>
      <span className={styles.colorTitle}>
        {selectedColor ?
          <>
            <span className={styles.colorTitleKey}>{t("PRODUCT.COLOR")}</span>
            &nbsp;
            <span>{selectedColor}</span>
          </> :
          t("PRODUCT.CHOOSE_A_COLOR")
        }
      </span>
      <div className={styles.cardValue}>

        {items.map((item, idx) => (
          <div
            key={item.buyRequest}
            style={{
              animationDelay: _stutter(idx),
            }}
            className={styles.col}
          >
            <Button
              className={cn(
                styles.button,
                { [styles.active]: value === item.buyRequest },
                { [styles.outOfStock]: item.buyRequest === null }
              )}
              value={item.buyRequest}
              disabled={item.buyRequest === null}
              onClick={e => {
                e.preventDefault();
                onChange(item.buyRequest);
              }}
            >
              <div
                className={styles.color}
                style={{ background: `linear-gradient(${formatConfigColors(item.product.attrLabels.color?.title)})` }} />

            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};
