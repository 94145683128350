/* @flow */

import type {
  SortableProductList,
  FilterableProductList as TFilterableProductList,
  FilterLocation,
  ProductSort,
} from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import React from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import Wrapper from "components/Wrapper";
import DropdownSort from "components/DropdownSort";
import { useUi } from "helpers/ui";
import ProductList from "components/ProductList";
import Button from "components/Button";
import PaginatedProductList from "components/PaginatedProductList";
import { useBrowser } from "@awardit/react-use-browser";
import { PAGE_SIZE } from "effects/route";
import {
  OffCanvasFilterMenu,
  OffCanvasSortMenu,
  Filterbar,
  StickBelowHeader,
  useFilter,
} from "@crossroads/ui-components";

import styles from "./styles.scss";

type Props = {
  children?: React$Node,
  updating: boolean,
  productList: SortableProductList | TFilterableProductList,
  load: FilterLocation => any,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
  totalCount?: number,
  category?: string,
};

type InnerProps = {
  children?: React$Node,
  updating: boolean,
  productList: SortableProductList | TFilterableProductList,
  load: FilterLocation => any,
  sortValues?: Array<ProductSort>,
  totalCount?: number,
};

const headerHeight = Number.parseInt(styles.headerHeight, 10) - 1;

const FilterableProductListInner = ({
  children, updating, productList, load, sortValues, totalCount = 0,
}: InnerProps) => {
  const t = useTranslate();
  const isBrowser = useBrowser();
  const { filterOCMOpen, setFilterOCMOpen, sortOCMOpen, setSortOCMOpen } = useUi();
  const filterConfig = {
    loading: updating,
    productList,
    usePoints: false,
    load,
    sortValues,
  };
  const filterState = useFilter(filterConfig);

  const FilterButton = () => (
    <Button
      className={styles.filterButton}
      size="medium"
      variant="ghost"
      onClick={() => setFilterOCMOpen(true)}
    >
      {t("FILTER.FILTERBAR.ALL_FILTERS")}
    </Button>
  );

  const SortButton = () => (
    <DropdownSort
      value={filterState.sort.value}
      items={filterState.sort.values || []}
      onChange={v => filterState.sort.setValue(v)}
    />
  );

  return (
    <>
      {isBrowser &&
        <>
          <OffCanvasFilterMenu
            isOpen={filterOCMOpen}
            close={() => setFilterOCMOpen(false)}
            filterState={filterState}
            side="RIGHT"
          />

          <OffCanvasSortMenu
            isOpen={sortOCMOpen}
            close={() => setSortOCMOpen(false)}
            filterState={filterState}
            side="RIGHT"
          />

          <StickBelowHeader
            className={styles.filterbar}
            headerHeight={headerHeight}
            render={fixed => (
              <Wrapper className={styles.filterWrapper}>
                {totalCount > 0 &&
                  <p className={styles.totalCount}>
                    {totalCount === 1 && t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_SINGULAR")}
                    {totalCount > 1 && t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_PLURAL", { itemsCount: totalCount })}
                  </p>
                }
                <Filterbar
                  className={cn({ [styles.filterbarFixed]: fixed })}
                  Button={FilterButton}
                  SortButton={SortButton}
                  filterState={filterState}
                  openFilters={() => setFilterOCMOpen(true)}
                />
              </Wrapper>
            )}
          />

          {!filterState.visible && <div className={styles.spacer} />}
        </>
      }

      {children}
    </>
  );
};

const useGetSortValues = (productList: SortableProductList | TFilterableProductList) => {
  const BLACKLISTED_SORT_VALUES = new Set(["position"]);
  const ONLY_DESC = new Set([]);
  const t = useTranslate();

  if (!productList.sortableBy) {
    return [];
  }

  const sortableBy = productList.sortableBy
    .filter(sb => !BLACKLISTED_SORT_VALUES.has(sb.code));

  const sortValues = [
    {
      code: ``,
      label: t("FILTER.FILTERBAR.SORT_DEFAULT"),
    },
  ];

  for (const value of sortableBy) {
    if (!ONLY_DESC.has(value.code)) {
      sortValues.push({
        code: `${value.code}_asc`,
        label: t(`FILTER.FILTERBAR.SORT_${value.code.toUpperCase()}_UP`),
      });
    }

    sortValues.push({
      code: `${value.code}_desc`,
      label: t(`FILTER.FILTERBAR.SORT_${value.code.toUpperCase()}_DOWN`),
    });
  }

  return sortValues;
};

const FilterableProductList = ({
  children,
  updating,
  productList,
  load,
  breadcrumbLinks,
  totalCount,
  category,
}: Props): React$Node => {
  const numPages = Math.ceil(productList.totalCount / PAGE_SIZE);
  const sortValues = useGetSortValues(productList);

  return (
    <FilterableProductListInner
      productList={productList}
      sortValues={sortValues}
      totalCount={totalCount}
      numPages={numPages}
      updating={updating}
      load={load}
    >
      <Wrapper>
        {children}
        <PaginatedProductList
          updating={updating}
          numPages={numPages}
          productList={productList}
          breadcrumbLinks={breadcrumbLinks}
          category={sortValues.length > 1 ? category : sortValues[0].label}
        />
      </Wrapper>
    </FilterableProductListInner>
  );
};

export const FilterableProductListHint = ({ children }: { children?: React$Node }): React$Node => (
  <Wrapper>
    <StickBelowHeader className={styles.filterbar}>
      <div className={styles.filterBarHint} />
    </StickBelowHeader>
    {children}
    <ProductList products={[null, null, null, null, null, null, null, null]} />
  </Wrapper>
);

export default FilterableProductList;
