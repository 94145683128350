/* @flow */

import type { ShippingAddressInput, BillingAddressInput } from "shop-state/types";

import styles from "./styles.scss";
import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import { useHistory } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";
import { useSendMessage, useData } from "crustate/react";
import { QuoteData } from "data";
import { getQuoteData } from "state/quote";
import { setAddresses } from "@crossroads/shop-state/quote";
import AddressComponent from "components/Address";
import ContactInfoComponent from "components/ContactInfo";
import Button from "components/Button";
import Container from "components/CheckoutView/Container";
import CustomerServiceLink from "components/CheckoutView/CustomerServiceLink";
import CartSummary from "components/CartSummary";
import { focusInvalidField } from "helpers/utils";
import { Form, rules, nestedRule, conditional, isEmail, isPhone, isRequired,
} from "@awardit/formaggio";
import { CheckboxField } from "components/Field";

export type FormState = {
  billing: BillingAddressInput,
  shipping: ShippingAddressInput,
  email: string,
  shipToSameAddress: boolean,
  checkDiscountCode: string,
};

type Props = {
  open: boolean,
  setOpen: boolean => void,
};

const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

const validationAddress = () => rules([
  isRequired("firstname"),
  isRequired("lastname"),
  isRequired("postcode"),
  isPhone("telephone"),
  isRequired("city"),
  nestedRule("street", rules([
    isRequired("0"),
  ])),
  isRequired("countryCode"),
]);

const validation = rules([
  isEmail("email"),
  isRequired("billing"),
  nestedRule("billing", validationAddress()),

  conditional(s => Boolean(s.billingNotUsedAsShipping), rules([
    isRequired("shipping"),
    nestedRule("shipping", validationAddress()),
  ])),
]);

const Step1 = ({ open, setOpen }: Props): React$Node => {
  const t = useTranslate();
  const history = useHistory();
  const sendMessage = useSendMessage();
  const quoteData = useData(QuoteData);
  const quote = getQuoteData(quoteData);
  const addresses = quote?.addresses || [];
  const quoteBilling = addresses.find(x => x.type === "billing");
  const quoteShipping = addresses.find(x => x.type === "shipping");
  const prevQuoteData = usePrevious(quoteData);
  const { info: { countries } } = useContext(StoreInfoContext);

  const validQuoteBillingCountry = useMemo(() => {
    return countries.find(c => c.code === quoteBilling?.country.code);
  }, [countries, quoteBilling]);

  const validQuoteShippingCountry = useMemo(() => {
    return countries.find(c => c.code === quoteShipping?.country.code);
  }, [countries, quoteShipping]);

  const [state, setState] = useState<FormState>(() => {
    if (!validQuoteBillingCountry) {
      return {
        email: "",
        telephone: "",
        billing: {
          firstname: "",
          lastname: "",
          city: "",
          countryCode: "",
          postcode: "",
          street: [""],
          telephone: "",
        },
        shipping: {
          firstname: "",
          lastname: "",
          city: "",
          countryCode: "",
          postcode: "",
          street: [""],
          telephone: "",
        },
        shipToSameAddress: true,
        checkDiscountCode: "",
      };
    }

    return {
      email: quote?.email || "",
      telephone: "",
      // TODO: Fix this type-error vs empty object above
      billing: {
        ...(quoteBilling: any),
        countryCode: validQuoteBillingCountry.code || "",
      },
      shipping: {
        ...(quoteShipping: any),
        countryCode: validQuoteShippingCountry?.code ||
          validQuoteBillingCountry.code || "",
      },
      shipToSameAddress: quoteBilling?.type === "billing" ? quoteBilling.isUsedAsShipping : false,
      checkDiscountCode: "",
    };
  });

  // TODO: Maybe update formaggio types to be more accepting or become
  //       structured to accept a specific structure
  const errors = validation((state: any));

  // Open summary when this route renders
  useEffect(() => {
    setOpen(true);
  }, [setOpen]);

  // Proceed on submit address
  useEffect(() => {
    if ((prevQuoteData && prevQuoteData.state === "SETTING_ADDRESS") && quoteData.state === "LOADED") {
      history.push("/checkout/overview");
    }
  }, [prevQuoteData, quoteData, sendMessage]);

  const submit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const billing = {
      firstname: state.billing.firstname,
      lastname: state.billing.lastname,
      street: state.billing.street,
      postcode: state.billing.postcode,
      city: state.billing.city,
      countryCode: state.billing.countryCode,
      telephone: state.billing.telephone,
    };

    const shipping = {
      firstname: state.shipping.firstname,
      lastname: state.shipping.lastname,
      street: state.shipping.street,
      postcode: state.shipping.postcode,
      city: state.shipping.city,
      countryCode: state.shipping.countryCode,
      telephone: state.shipping.telephone,
    };

    sendMessage(setAddresses(state.email, billing, shipping, state.shipToSameAddress));
  };

  return (
    <Form
      value={(state: any)}
      errors={errors}
      onError={focusInvalidField}
      onChange={x => {
        setState({ ...state, ...(x: any) });
      }}
      onSubmit={submit}
    >
      <Container
        right={
          <div>
            <CartSummary open={open} setOpen={setOpen}>
              <div className={styles.submitButtonContainer}>
                <Button
                  className={styles.submitButton}
                  type="submit"
                  variant="primary"
                  loading={quoteData.state === "SETTING_ADDRESS"}
                >
                  {t("CHECKOUT.TO_PAYMENT")}
                </Button>
              </div>
            </CartSummary>

            <CustomerServiceLink />
          </div>
        }
      >
        <div className={styles.left}>
          <div className={styles.row}>
            <ContactInfoComponent type="billing" />
            <AddressComponent type="billing" />
          </div>

          <div className={styles.checkbox}>
            <CheckboxField className={styles.checkbox} name="shipToSameAddress" checked={state.shipToSameAddress}>
              {t("CHECKOUT.ADDRESS.SHIP_TO_SAME_ADDRESS")}
            </CheckboxField>
          </div>

          {!state.shipToSameAddress &&
            <>
              <h2 className={styles.heading}>{t("CHECKOUT.ADDRESS.SHIPPING")}</h2>
              <div className={styles.row}>
                <AddressComponent type="shipping" />
              </div>
            </>
          }
        </div>
      </Container>
    </Form>
  );
};

export default Step1;
