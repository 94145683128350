/* @flow */

import type { BreadcrumbLink } from "@crossroads/ui-components";

import React from "react";
import cn from "classnames";
import { Breadcrumbs } from "@crossroads/ui-components";
import Wrapper from "components/Wrapper";

import styles from "./styles.scss";

type Props = {
  title: string,
  subTitle?: string,
  description?: string,
  images?: {
    small?: {
      x1: string,
      x2: string,
    },
    medium?: {
      x1: string,
      x2: string,
    },
    large?: {
      x1: string,
      x2: string,
    },
  },
  className?: string,
  breadcrumbLinks: $ReadOnlyArray<BreadcrumbLink>,
  breadcrumbCurrent: string,
  short?: boolean,
  +logo: ?{ x1: string, x2: string },
};

type HintProps = {
  title?: string,
  className?: string,
  breadcrumbLinks: $ReadOnlyArray<BreadcrumbLink>,
  breadcrumbCurrent: string,
};

const Delimeter = (): React$Node => <span className={styles.delimeter}>/</span>;

const ProductListHero = ({
  title,
  subTitle,
  description,
  images,
  className,
  breadcrumbLinks,
  breadcrumbCurrent,
  short = false,
  logo,
}: Props): React$Node => {
  const { small: smallImage, medium: mediumImage, large: largeImage } = images || {};
  const gotImage = smallImage || mediumImage || largeImage || false;

  return (
    <div
      className={cn(
        styles.block,
        className,
        { [styles.gotImage]: gotImage },
        { [styles.fallback]: !gotImage },
        { [styles.short]: short }
      )}
      style={{
        backgroundImage: gotImage ? `url(${smallImage?.x1 || mediumImage?.x1 || largeImage?.x1 || ""})` : "url(/assets/hero-bg.svg)",
      }}
    >

      <div className={styles.innerBlock}>
        <Wrapper className={styles.wrapper}>
          <div className={styles.content}>
            <Breadcrumbs
              className={styles.breadcrumbs}
              links={breadcrumbLinks}
              Delimeter={Delimeter}
              current={breadcrumbCurrent}
              variant="white"
            />

            {logo &&
              <div className={styles.logo}>
                <picture>
                  <source srcSet={`${logo.x1}, ${logo.x2} 2x`} />
                  <img src={logo.x1} alt="Logo" />
                </picture>
              </div>
            }

            <div className={styles.text}>
              {title &&
                <h1 className={styles.title}>{title}</h1>
              }

              {subTitle &&
                <h2 className={styles.subTitle}>{subTitle}</h2>
              }

              {description &&
                <p className={styles.description}>
                  {description}
                </p>
              }
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  );
};

export const ProductListHeroHint = ({
  title,
  className,
  breadcrumbLinks,
  breadcrumbCurrent,
}: HintProps): React$Node => {
  return (
    <div className={cn(styles.blockHint, className)}>
      <Wrapper className={styles.wrapper}>
        <div className={styles.content}>
          <Breadcrumbs
            className={styles.breadcrumbs}
            links={breadcrumbLinks}
            Delimeter={Delimeter}
            current={breadcrumbCurrent}
            variant="white"
          />
          <div className={styles.text}>
            {title &&
              <h1 className={styles.title}>{title}</h1>
            }
            <p className={styles.description} />
          </div>
        </div>

        <div className={styles.right} />
      </Wrapper>
    </div>
  );
};

export default ProductListHero;
