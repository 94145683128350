/* @flow */

import type { RouterHistory as History, Location } from "react-router";

import React from "react";
import { Route, Switch } from "react-router";
import Redirect from "@crossroads/react-router-redirect";
import CmsPageView from "components/CmsPageView";
import {
  RouteData, OrderData, CmsData,
} from "data";
import { useData } from "crustate/react";
import App from "components/App";
import GuestApp from "components/GuestApp";
import LoadingView from "components/LoadingView";
import LoginView from "components/LoginView";
import CategoryView, { HintCategoryView } from "components/CategoryView";
import ProductView, { HintProductView } from "components/ProductView";
import SuccessView from "components/SuccessView";
import CheckoutView from "components/CheckoutView";
import NotFoundView from "components/NotFoundView";
import { parseParams } from "helpers/location-search-string";
import { locationWithDefaults } from "helpers/use-update-product-list";
import { useCampaign } from "context/campaign-context";

type RoutesProps = {
  location: Location,
  history: History,
};

type RouteViewProps = {
  location: Location,
  history: History,
};

const RouteView = ({ location, history }: RouteViewProps): React$Node => {
  const data = useData(RouteData);

  if (data.state === "LOADING") {
    if (data.hint) {
      switch (data.hint.type) {
        case "product":
          return <HintProductView product={data.hint.product} />;
        case "category":
          return <HintCategoryView category={data.hint.category} />;
        case "cms_page":
          return <CmsPageView cmsPage={data.hint.cmsPage} />;
        default:
      }
    }

    return <LoadingView />;
  }

  if (!data.route) {
    // TODO: Check error msg
    return <NotFoundView />;
  }

  switch (data.route.type) {
    case "product":
      return <ProductView product={data.route.product} />;
    case "category":
      return <CategoryView category={data.route.category} location={location} history={history} />;
    case "cms_page":
      return <CmsPageView cmsPage={data.route.cmsPage} />;
    case "redirect":
      return <Redirect to={data.route.url} />;
    default:
      return null;
  }
};

const Routes = ({ location, history }: RoutesProps): React$Node => {
  const { loggedIn, rootCategory } = useCampaign();

  if (!loggedIn) {
    return (
      <GuestApp location={location} history={history}>
        <Switch>
          <Route exact path="/" component={LoginView} />
          <Route
            exact
            path="/privacy-policy"
            render={({ location: { state }, history }) => {
              return (
                <RouteData.Provider
                  location={locationWithDefaults(location)}
                  hint={state && state.hint}
                >
                  <RouteView location={location} history={history} />
                </RouteData.Provider>
              );
            }}
          />
          <Route path="/(.+)" render={() => <Redirect to="/" />} />
        </Switch>
      </GuestApp>
    );
  }

  return (
    <Switch>
      <App location={location} history={history}>
        <Switch>
          {rootCategory && rootCategory.url !== "/" &&
            <Route
              exact
              path="/"
              render={() => (
                <Redirect to={rootCategory.url} />
              )}
            />
          }

          <Route
            path="/checkout/success"
            render={() => (
              <OrderData.Provider>
                <SuccessView />
              </OrderData.Provider>
            )} />

          <Route
            exact
            path="/checkout/:step?"
            render={() => (
              <CmsData.Provider url="terms">
                <CheckoutView />
              </CmsData.Provider>
            )} />

          <Route
            path="/(.+)"
            render={({ location: { pathname, search, state }, history }) => {
              const { page } = parseParams(search);

              return (
                <RouteData.Provider
                  location={locationWithDefaults(location)}
                  url={pathname}
                  name={`route_${pathname}`}
                  page={page}
                  hint={state && state.hint}
                >
                  <RouteView location={location} history={history} />
                </RouteData.Provider>
              );
            }} />
        </Switch>
      </App>
    </Switch>
  );
};

const AppRoutes = (): React$Node => <Route component={Routes} />;

export default AppRoutes;
